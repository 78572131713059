<template>
    <div class="container-fluid">
         <b-row>
            <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                   <template v-slot:headerTitle>
                        <h4 class="card-title">Padding</h4>
                   </template>
                   <template v-slot:body>
                     <pre class="iq-helper-classes m-0">
.p-0 { padding: 0 !important; }
.p-1 { padding: .25rem !important; }
.p-2 { padding: .5rem !important; }
.p-3 { padding: 1rem !important; }
.p-4 { padding: 1.5rem !important; }
.p-5 { padding: 3rem !important; }</pre>
                  </template>
               </card>
            </b-col>
            <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                   <template v-slot:headerTitle>
                        <h4 class="card-title">Padding Left</h4>
                    </template>
                 <template v-slot:body>
                      <pre class="iq-helper-classes m-0">
.pl-0 { padding: 0 !important; }
.pl-1 { padding: .25rem !important; }
.pl-2 { padding: .5rem !important; }
.pl-3 { padding: 1rem !important; }
.pl-4 { padding: 1.5rem !important; }
.pl-5 { padding: 3rem !important; }</pre>
                  </template>
               </card>
            </b-col>
           <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                 <template v-slot:headerTitle>
                        <h4 class="card-title">Padding right</h4>
                     </template>
                 <template v-slot:body> 
                     <pre class="iq-helper-classes m-0">
.pr-0 { padding: 0 !important; }
.pr-1 { padding: .25rem !important; }
.pr-2 { padding: .5rem !important; }
.pr-3 { padding: 1rem !important; }
.pr-4 { padding: 1.5rem !important; }
.pr-5 { padding: 3rem !important; }</pre>
                  </template>
               </card>
            </b-col>
             <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                 <template v-slot:headerTitle>
                        <h4 class="card-title">Padding Top</h4>
                    </template>
                  <template v-slot:body>
                      <pre class="iq-helper-classes m-0">
.pt-0 { padding: 0 !important; }
.pt-1 { padding: .25rem !important; }
.pt-2 { padding: .5rem !important; }
.pt-3 { padding: 1rem !important; }
.pt-4 { padding: 1.5rem !important; }
.pt-5 { padding: 3rem !important; }</pre>
                   </template>
               </card>
            </b-col>
             <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Padding Bottom</h4>
                    </template>
                  <template v-slot:body>
                      <pre class="iq-helper-classes m-0">
.pb-0 { padding: 0 !important; }
.pb-1 { padding: .25rem !important; }
.pb-2 { padding: .5rem !important; }
.pb-3 { padding: 1rem !important; }
.pb-4 { padding: 1.5rem !important; }
.pb-5 { padding: 3rem !important; }</pre>
                  </template>
               </card>
             </b-col>
            <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                 <template v-slot:headerTitle>
                        <h4 class="card-title">Margin</h4>
                </template>
                  <template v-slot:body> <pre class="iq-helper-classes mb-0">
.m-0 { margin: 0 !important; }
.m-1 { margin: .25rem !important; }
.m-2 { margin: .5rem !important; }
.m-3 { margin: 1rem !important; }
.m-4 { margin: 1.5rem !important; }
.m-5 { margin: 3rem !important; }</pre>
                 </template>
               </card>
             </b-col>
             <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Margin Left</h4>
                     </template>
                  <template v-slot:body> <pre class="iq-helper-classes m-0">
.ml-0 { margin: 0 !important; }
.ml-1 { margin: .25rem !important; }
.ml-2 { margin: .5rem !important; }
.ml-3 { margin: 1rem !important; }
.ml-4 { margin: 1.5rem !important; }
.ml-5 { margin: 3rem !important; }</pre>
                  </template>
               </card>
            </b-col>
            <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Margin Right</h4>
                     </template>
                 <template v-slot:body><pre class="iq-helper-classes m-0">
.mr-0 { margin: 0 !important; }
.mr-1 { margin: .25rem !important; }
.mr-2 { margin: .5rem !important; }
.mr-3 { margin: 1rem !important; }
.mr-4 { margin: 1.5rem !important; }
.mr-5 { margin: 3rem !important; }</pre>
                  </template>
               </card>
             </b-col>
             <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Margin Top</h4>
                     </template>
                  <template v-slot:body> <pre class="iq-helper-classes m-0">
.mt-0 { margin: 0 !important; }
.mt-1 { margin: .25rem !important; }
.mt-2 { margin: .5rem !important; }
.mt-3 { margin: 1rem !important; }
.mt-4 { margin: 1.5rem !important; }
.mt-5 { margin: 3rem !important; }</pre>
                   </template>
               </card>
            </b-col>
            <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Margin Bottom</h4>
                    </template>
                  <template v-slot:body>  <pre class="iq-helper-classes m-0">
.mb-0 { margin: 0 !important; }
.mb-1 { margin: .25rem !important; }
.mb-2 { margin: .5rem !important; }
.mb-3 { margin: 1rem !important; }
.mb-4 { margin: 1.5rem !important; }
.mb-5 { margin: 3rem !important; }</pre>
                  </template>
               </card>
           </b-col>
             <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Display Block</h4>
                     </template>
                  <template v-slot:body>  <pre class="iq-helper-classes m-0">
.d-block { display: block !important; }
.d-inline-block { display: inline-block !important; }
.d-flex { display: display-flex !important; }</pre>
                  </template>
               </card>
            </b-col>
            <b-col lg="4" md="6">
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Alignment Item</h4>
                     </template>
                  <template v-slot:body>  <pre class="iq-helper-classes m-0">
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }
.float-left { float: left; }
.float-right { float: right; }
.float-none { float: none; }</pre>
                  </template>
               </card>
            </b-col>
            <b-col lg="6" md="6" >
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Line Height</h4>
                      </template>
                  <template v-slot:body> <pre class="iq-helper-classes m-0">
.line-height { line-height: normal !important; }
.line-height-2 { line-height: 2 !important; }
.line-height-3 { line-height: 20px !important; }
.line-height-4 { line-height: 40px !important; }
.line-height-5 { line-height: 45px !important; }
.line-height-6 { line-height: 60px; }
.line-height-7 { line-height: 50px !important; }</pre>
                  </template>
               </card>
            </b-col>
            <b-col lg="6" md="6" >
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Font Weight</h4>
                      </template>
                  <template v-slot:body>  <pre class="iq-helper-classes m-0">
.font-weight-300 { font-weight: 300 !important; }
.font-weight-400 { font-weight: 400 !important; }
.font-weight-500 { font-weight: 500 !important; }
.font-weight-600 { font-weight: 600 !important; }
.font-weight-700 { font-weight: 700 !important; }</pre>
                  </template>
               </card>
            </b-col>
            <b-col lg="6" md="6" >
               <card class=" card-block card-stretch card-height">
                   <template v-slot:headerTitle>
                        <h4 class="card-title">Font Size</h4>
                    </template>
                  <template v-slot:body>  <pre class="iq-helper-classes m-0">
.font-size-12 { font-size: 12px !important; }
.font-size-14 { font-size: 14px !important; }
.font-size-16 { font-size: 16px !important; }
.font-size-18 { font-size: 18px !important; }
.font-size-20 { font-size: 20px !important; }
.font-size-22 { font-size: 22px !important; }
.font-size-24 { font-size: 24px !important; }
.font-size-32 { font-size: 32px !important; }
.font-size-40 { font-size: 40px !important; }
.font-size-80 { font-size: 80px !important; }</pre>
                  </template>
               </card>
            </b-col>
            <b-col lg="6" md="6" >
               <card class=" card-block card-stretch card-height">
                 <template v-slot:headerTitle>
                        <h4 class="card-title">Text Color</h4>
                     </template>
                  <template v-slot:body> <pre class="iq-helper-classes m-0">
.text-primary { color: var(--primary) !important; }
.text-secondary { color: var(--secondary) !important; }
.text-success { color: var(--success) !important; }
.text-danger { color: var(--danger) !important; }
.text-warning { color: var(--warning) !important; }
.text-info { color: var(--info) !important; }
.text-light { color: var(--light) !important; }
.text-dark { color: var(--dark) !important; }
.text-white { color: var(--white) !important; }
.text-black { color: var(--black) !important; }
.text-body { color: var(--body-text) !important; }
.text-title { color: var(--body-text) !important; }</pre>
                   </template>
               </card>
            </b-col>
            <b-col lg="6" md="6" >
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Background Color</h4>
                   </template>
                  <template v-slot:body> <pre class="iq-helper-classes m-0">
.bg-primary, .badge-primary {
color: var(--white);
background: var(--primary) !important;
}

.bg-secondary, .badge-secondary {
color: var(--white);
background-color: var(--secondary) !important;
}

.bg-success, .badge-success {
color: var(--white);
background: var(--success) !important;
}

.bg-danger, .badge-danger {
color: var(--white);
background: var(--danger) !important;
}

.bg-warning, .badge-warning {
color: var(--white);
background: var(--warning) !important;
}

.bg-info, .badge-info {
color: var(--white) !important;
background: var(--info) !important;
}

.bg-light, .badge-light {
background-color: var(--light) !important;
}

.bg-dark, .badge-dark {
color: var(--white);
background-color: var(--dark) !important;
}

.bg-close, .badge-close {
color: var(--white);
background-color: var(--close) !important;
}</pre>
                  </template>
               </card>
            </b-col>
           <b-col lg="6" md="6" >
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Light Background Color</h4>
                    </template>
                <template v-slot:body> <pre class="iq-helper-classes m-0">
.bg-primary {
background: var(--light-primary) !important;
color: var(--primary) !important;
}

.bg-secondary {
background: var(--light-secondary) !important;
color: var(--secondary) !important;
}

.bg-success {
background: var(--light-success) !important;
color: var(--success) !important;
}

.bg-danger {
background: var(--light-danger) !important;
color: var(--danger) !important;
}

.bg-warning {
background: var(--light-warning) !important;
color: var(--warning) !important;
}

.bg-info {
background: var(--light-info) !important;
color: var(--info) !important;
}

.bg-dark {
background: var(--light-dark) !important;
color: var(--dark) !important;
}

.bg-close {
background: var(--light-close) !important;
color: var(--dark) !important;
}</pre>
                  </template>
               </card>
             </b-col>
            <b-col lg="6" md="6" >
               <card class=" card-block card-stretch card-height">
                   <template v-slot:headerTitle>
                        <h4 class="card-title">Hover Background Color</h4>
                    </template>
                  <template v-slot:body><pre class="iq-helper-classes m-0">
.bg-primary-hover:hover {
background: var(--light-primary) !important;
color: var(--primary);
}

.bg-primary-secondary-hover:hover {
background: var(--light-secondary) !important;
}

.bg-primary-success-hover:hover {
background: var(--light-success) !important;
}

.bg-primary-danger-hover:hover {
background: var(--light-danger) !important;
}

.bg-primary-warning-hover:hover {
background: var(--light-warning) !important;
}

.bg-primary-info-hover:hover {
background: var(--light-info) !important;
}

.bg-primary-dark-hover:hover {
background: var(--light-dark) !important;
}</pre>
                   </template>
               </card>
           </b-col>
            <b-col lg="6" md="6" >
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Button Color</h4>
                     </template>
                  <template v-slot:body> <pre class="iq-helper-classes m-0">
.btn-primary {
background: var(--primary);
border-color: var(--primary);
}

.btn-secondary {
background-color: var(--secondary);
border-color: var(--secondary);
}

.btn-success {
background-color: var(--success);
border-color: var(--success);
}

.btn-danger {
background-color: var(--danger);
border-color: var(--danger);
}

.btn-warning {
background-color: var(--warning);
border-color: var(--warning);
color: var(--white);
}

.btn-info {
background-color: var(--info);
border-color: var(--info);
}

.btn-light {
background-color: var(--light);
border-color: var(--light);
}

.btn-dark {
background-color: var(--dark);
border-color: var(--dark);
}

.btn-white {
background-color: var(--white);
border-color: var(--white);
}</pre>
                 </template>
               </card>
            </b-col>
            <b-col lg="6" md="6" >
               <card class=" card-block card-stretch card-height">
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Button Hover Color</h4>
                    </template>
                  <template v-slot:body><pre class="iq-helper-classes m-0">
.btn-primary:hover {
background: var(--primary-hover);
border-color: var(--primary-hover);
}

.btn-secondary:hover {
background-color: var(--secondary-hover);
border-color: var(--secondary-hover);
}

.btn-success:hover {
background-color: var(--success-hover);
border-color: var(--success-hover);
}

.btn-danger:hover {
background-color: var(--danger-hover);
border-color: var(--danger-hover);
}

.btn-warning:hover {
background-color: var(--warning-hover);
border-color: var(--warning-hover);
}

.btn-info:hover {
background-color: var(--info-hover);
border-color: var(--info-hover);
}

.btn-light:hover {
background-color: var(--light-hover);
border-color: var(--light-hover);
}

.btn-dark:hover {
background-color: var(--dark-hover);
border-color: var(--dark-hover);
}</pre>
                  </template>
               </card>
            </b-col>
           <b-col lg="6" md="6" >
               <card class=" card-block card-stretch card-height">
                   <template v-slot:headerTitle>
                        <h4 class="card-title">Border Color</h4>
                    </template>
                  <template v-slot:body> <pre class="iq-helper-classes m-0">
.border-primary {
border-color: var(--primary) !important;
}

.border-secondary {
border-color: var(--secondary) !important;
}

.border-success {
border-color: var(--success) !important;
}

.border-danger {
border-color: var(--danger) !important;
}

.border-warning {
border-color: var(--warning) !important;
}

.border-info {
border-color: var(--info) !important;
}

.border-light {
border-color: var(--light) !important;
}

.border-dark {
border-color: var(--dark) !important;
}

.border-white {
border-color: var(--white) !important;
}</pre>
                  </template>
               </card>
           </b-col>
         </b-row>
      </div>
</template>
<script>
export default {
    name:'UiHelperClasses'
}
</script>